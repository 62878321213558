import React, { FC } from 'react'
import { nanoid } from 'nanoid'
import { OverviewIntroCard } from '@mch-group/uikit-components'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'
import { detectDpr } from '@utils'
import { CustomLinkTag } from '@components'

interface IOverviewIntro {
  id: string | undefined
  moduleName: string
  oi_variant: React.ComponentProps<typeof OverviewIntroCard>['variant']
  oi_overview_width: string
}

interface IOverviewIntroItem {
  id: number | string
  title: string
  alt: string
  introDescription?: string
  image: string
  url: string
  linkRenderer?: (link: string, label: string) => JSX.Element
}

const OverviewIntro: FC<IOverviewIntro> = ({ id, oi_variant, oi_overview_width, ...props }) => {
  const data: Array<IOverviewIntroItem> = []

  Array(12)
    .fill({})
    .map((item, index) => {
      const number = index + 1
      if (props[`oi_intro_title_${number}`] && props[`oi_intro_image_${number}`] && props[`oi_intro_url_${number}`]) {
        data.push({
          id: nanoid(),
          title: props[`oi_intro_title_${number}`],
          alt: props[`oi_intro_title_${number}`],
          introDescription: props[`oi_intro_description_${number}`],
          image: uploadTransformationUrl(props[`oi_intro_image_${number}`], `q_auto,w_720,${detectDpr()}`, {
            transformUrl: cloudinary.throughCloudfront
          }),
          url: props[`oi_intro_url_${number}`],
          linkRenderer: (link, label) => (
            <CustomLinkTag newTab={props[`oi_intro_open_in_new_tab_${number}`] === 'true'} url={link}>
              {label}
            </CustomLinkTag>
          )
        })
      }
    })

  return (
    <section id={id} className='overview-intro cms-module'>
      <OverviewIntroCard
        variant={oi_variant}
        containerClass='px-5 px-md-6 px-lg-7 px-xl-8'
        splitColsInMobile={oi_overview_width === '50%'}
        listView
        data={data}
      />
    </section>
  )
}

export default OverviewIntro
